.slideshowcontainer {
    width: 100%;
    overflow: hidden;
}

.slideshow {
    display: flex;
    justify-content: space-between;
    margin: 80px 0;

    @media (max-width: 768px) {
        width: 300%;
    }
}