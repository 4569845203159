nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 1100px;
    max-width: 100%;
    margin: auto;
    text-align: left;
    padding: 5px 10px;

    .maintitle {
        color: white;
        font-size: 2.5em;
    }
}
    
.hero-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    .hero-text {
        color: white;

        .subtitle {
            font-size: 1.5rem;
        }

        h1, .subtitle {
            display: block;
            margin: 15px 0;
        }

        @media (max-width: 768px) {
            .subtitle {
                font-size: 1em;
            }
    
            h1 {
                font-size: 2.5em;
            }
        }
    }

    .hero-nexticon {
        position: absolute;
        bottom: 20px;
        border: 4px solid white;
        height: 80px;
        width: 80px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3em;
    }
}