.slidecontainer {
    height: 600px;
    width: 30%;

    @media (max-width: 768px) {
        width: 100%;
    }

    .slidercontent {
        position: absolute;
        bottom: 0;
        width: 100%;

        .sliderlogo {
            height: 30px;
            margin-bottom: 30px;

            img {
                height: 100%;
                display: block;
                margin: auto;
            }
        }

        .slidertext {
            height: 6em;
            color: white;
            text-align: center;
            font-size: 2em;
            line-height: 1.2em;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            img {
                transform: translateY(-5px);
            }
        }
    }
}