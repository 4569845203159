.gallerycontainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1px;
    background: #9CA0A4;
    margin: 80px 0;

    @media (max-width: 768px) {
        grid-template-columns: 50% 50%;
    }

    .galleryitem {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 140px;
        background: #F9F9F9;

        img {
            max-width: 100%;
        }
    }
}