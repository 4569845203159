.contactblock {
    display: flex;
    margin: 80px 0;

    @media (max-width: 768px) {
        display: block;
    }

    &-hero {
        min-width: 40%;
        font-size: 4em;

        @media (max-width: 768px) {
                text-align: center;
                margin-bottom: 30px;
        }
    }

    &-form {
        font-size: 2.5em;
        line-height: 2em;

        .inputcontainer {
            display: inline-block;
            width: 300px;

            input {
                border-width: 0;
                background: transparent;
                height: 1em;
                font-size: 1em;
                padding: 30px 0;
                overflow: visible;
                width: 100%;

                &:focus {
                    outline-width: 0;
                }

                &::placeholder {
                    text-align: center;
                }
            }

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: #c3c3c3;
                bottom: 0;
            }
        }
    }
}